import React, { useEffect } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateAndTime } from "constant/calenderView";

import {
  ADMIN_APPT_TYPES_LABELS,
  CANCELLATION_REASONS,
  CR_FAILURE_CODES,
  CR_SUCCESS_CODES,
  CR_SYNCING_CODES,
  CR_SYNC_AND_FAILURE_STATUSES,
  SCHOOL_APPTS,
} from "../../../constant/BiPortalConstants";
import { Appointment } from "model/appointments";

import {
  checkIfItisToday,
  getCrStatusString,
  isFailedToSync,
  isEventSyncing,
} from "../../Appointments/biportal/AppointmentForms/appointments.helper";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "sharedComponents/reactHookComponents/popup/popup";
import Popover from "@mui/material/Popover";
import "./AppointmentComponent.scss";
import { IAddress } from "model/calender/appointments";
import { ReactComponent as Eye } from "../../../assets/Eye.svg";
import { ApiCalls } from "../biportal/AppointmentForms/AllAppointmentsApi";
import { isAndroid, isIOS, isMacOs, isWindows } from "react-device-detect";
import {
  APPT_LOCATION_TYPES,
  APPT_TYPES,
  Decimal_ZERO,
  EVENT_STATUS,
  String_Initialiser,
} from "constant/AppConstants";
import { useSubmitApi } from "customHooks/authHook";
import { useMsal } from "@azure/msal-react";
import CorticaLoader from "sharedComponents/CorticaLoader/CorticaLoader";

interface IProps {
  key: number;
  appointment: Appointment;
  dayConfirmed: boolean;
  activeDay?: string;
  getAppointments: (
    startStr: string,
    endStr: string,
    setLoading: boolean
  ) => void;
  startDateProps: any;
  endDateProps: any;
  setShouldPoll: (val: boolean) => void;
  setResyncCrId: (val: number) => void;
  filteredResyncAppointments: any;
  resyncDisable: boolean;
  setResyncDisable: (val: boolean) => void;
  resyncCount: number;
  setResyncCount: (val: number) => void;
  isPopupOpen: boolean;
  setPopupOpen: (val: boolean) => void;
}

const AppointmentComponent: React.FC<IProps> = ({
  appointment,
  dayConfirmed,
  activeDay,
  getAppointments,
  startDateProps,
  endDateProps,
  setShouldPoll,
  setResyncCrId,
  filteredResyncAppointments,
  resyncDisable,
  setResyncDisable,
  resyncCount,
  setResyncCount,
  isPopupOpen,
  setPopupOpen,
}) => {
  const history = useHistory();

  const { submitApiCall } = useSubmitApi();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [converted, setConverted] = React.useState<boolean | undefined>();
  const [softLoading, setSoftLoading] = React.useState<boolean>(false);
  const [showOption, setShowOptions] = React.useState<boolean>(true);
  const [showModal, setModalStatus] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [loading, setloading] = React.useState<boolean>(false);
  const [isPopupOpenObserver, setPopupOpenObserver] =
    React.useState<boolean>(false);

  const [msgShow, setConvMsg] = React.useState<boolean>(false);

  const [backendError, setBackendError] = React.useState<boolean>(false);

  const isCancelled = appointment.status === EVENT_STATUS.canceled;
  const foundReason = CANCELLATION_REASONS.find(
    (reason) => reason.value === appointment?.cancelReason
  );
  const cancelLabel = foundReason ? foundReason : String_Initialiser;

  const isDriveTime =
    appointment.title === ADMIN_APPT_TYPES_LABELS.driveTime ||
    appointment.title === ADMIN_APPT_TYPES_LABELS.mileageOnly;
  const isPTO =
    appointment.title === ADMIN_APPT_TYPES_LABELS.pto ||
    appointment.title === ADMIN_APPT_TYPES_LABELS.unavailable ||
    appointment.title === ADMIN_APPT_TYPES_LABELS.callout;
  const hasDriveTimeMileage =
    (appointment.title === ADMIN_APPT_TYPES_LABELS.driveTime ||
      appointment.title === ADMIN_APPT_TYPES_LABELS.mileageOnly) &&
    appointment.driveTimeMileage === null &&
    appointment.reimbursableMileage === null
      ? false
      : true;
  const isAbaAtSchool = SCHOOL_APPTS.includes(appointment.title);

  const fetchLeadEvent = async (leadEventId: number) => {
    try {
      const leadEvent = await submitApiCall(ApiCalls.GetEvent, {
        eventId: leadEventId,
      });
      return leadEvent;
    } catch (error) {
      console.error("Error fetching lead event:", error);
      setloading(false);
      return error;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setModalStatus(event.currentTarget);
    checkConversion(apiData);
  };

  const removeDecimalIfZero = (value: number): string => {
    const stringValue = value.toString();
    const decimalIndex = stringValue.indexOf(".");
    if (decimalIndex !== -1) {
      const decimalPart = stringValue.substring(decimalIndex + 1);
      if (decimalPart === Decimal_ZERO) {
        return stringValue.substring(0, decimalIndex);
      }
    }
    return stringValue;
  };

  const handleClickFailed = async (appointment: any, event: any) => {
    if (activeAccount) {
      setResyncCrId(appointment.id);
      setResyncCount(0);
      setResyncDisable(true);
      event.stopPropagation();
      setShouldPoll(false);
      const payload = {
        eventId: apiData.eventId,
        providerName: activeAccount.username,
        timezone: apiData.timezone,
      };

      try {
        const response = await submitApiCall(
          ApiCalls.AppointmentResyncCall,
          payload
        );

        if (response) {
          setShouldPoll(true);
          getAppointments(startDateProps, endDateProps, false);
        }
      } catch (err) {
        console.error("[reSyncApptCall] Error in API call:", err);
        setShouldPoll(true);
        getAppointments(startDateProps, endDateProps, false);
        setPopupOpen(true);
        setResyncDisable(false);
      }
      setResyncCount(1);
    }
  };

  useEffect(() => {
    if (CR_SYNCING_CODES.includes(filteredResyncAppointments[0]?.crStatus)) {
      setResyncCount(2);
    }
    if (
      CR_FAILURE_CODES.includes(filteredResyncAppointments[0]?.crStatus) &&
      resyncCount === 2
    ) {
      if (CR_FAILURE_CODES.includes(filteredResyncAppointments[0]?.crStatus))
        setPopupOpen(true);
      setResyncCount(0);
      setResyncDisable(false);
    } else if (
      CR_SUCCESS_CODES.includes(filteredResyncAppointments[0]?.crStatus)
    ) {
      setResyncCount(0);
      setResyncDisable(false);
    }
  }, [filteredResyncAppointments]);

  const apiData = { eventId: appointment.id, timezone: appointment.clinic.timezone };

  async function checkConversion(payload: any) {
    setShowOptions(false);
    setSoftLoading(true);
    setBackendError(false);
    try {
      const response = await submitApiCall(
        ApiCalls.ConfirmConversionCall,
        payload
      );
      setConverted(response?.data?.isConverted);
      setConvMsg(response?.data?.isConverted);
    } catch (error) {
      setBackendError(true);
    }
    setSoftLoading(false);
  }

  useEffect(() => {
    if (converted) {
      setShowOptions(false);
    } else {
      setShowOptions(true);
    }
  }, [converted]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (showModal && event.target.closest("#customModal") === null) {
        // setModalStatus(false);
        setConverted(undefined);
        setShowOptions(false);
        setConvMsg(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showModal]);

  const handleCancelAppointment = async () => {
    if (appointment.leadEventId) {
      try {
        setloading(true);
        const response: any = await fetchLeadEvent(appointment.leadEventId!);
        setloading(false);
        history.push({
          pathname: "/cancel-appointment", // New route
          state: {
            providerId: appointment.provider_id, // Pass providerId in state
            providerName: appointment.provider_name, // Pass providerName in state
            providerClinicId: appointment.client_id,
            providerEmail: appointment.provider_name,
            appointment: appointment,
            activeDay: moment(activeDay).toISOString(),
            leadEvent: response.data,
          },
        });
      } catch (error) {
        setloading(false);
        console.error("Failed to fetch lead event", error);
      }
    } else {
      history.push({
        pathname: "/cancel-appointment", // New route
        state: {
          providerId: appointment.provider_id, // Pass providerId in state
          providerName: appointment.provider_name, // Pass providerName in state
          providerClinicId: appointment.client_id,
          providerEmail: appointment.provider_name,
          appointment: appointment,
          activeDay: moment(activeDay).toISOString(),
        },
      });
    }
  };

  const handleEditAppointment = async () => {
    const apptDate = moment(activeDay).toISOString();

    if (appointment.leadEventId) {
      try {
        setloading(true);
        const response: any = await fetchLeadEvent(appointment.leadEventId!);
        setloading(false);
        if (response.data) {
          history.push({
            pathname: "/edit-observer",
            state: {
              appointment: appointment,
              activeDay: apptDate,
              leadEvent: response.data,
            },
          });
        }
      } catch (error) {
        setloading(false);
        console.error("Failed to fetch lead event", error);
      }
    } else {
      history.push({
        pathname: "/edit-appointment",
        state: {
          appointment: appointment,
          activeDay: apptDate,
        },
      });
    }
  };

  const statusText = getCrStatusString(
    appointment.crStatus,
    appointment?.isCancelling,
    appointment?.isHardCancelling
  );

  const syncing = isEventSyncing(
    appointment.crStatus,
    appointment?.isCancelling,
    appointment?.isHardCancelling
  );

  const disableEdit =
    CR_SYNC_AND_FAILURE_STATUSES.includes(appointment.crStatus) ||
    isCancelled ||
    (appointment?.isCancelling &&
      appointment.crStatus !== 101 &&
      appointment.crStatus !== 102) ||
    dayConfirmed ||
    appointment?.isHardCancelling ||
    syncing;

  const notTodayAppointment = checkIfItisToday(appointment.startAt);
  const notLaterInTheDayAppointment = moment(appointment.endAt).isAfter(
    moment()
  );

  const failed = isFailedToSync(appointment.crStatus);

  const handleAppointmentOpenPopup = () => {
    if (failed) setPopupOpen(true);
  };

  const handleAppointmentClosePopup = () => {
    setPopupOpen(false);
  };

  const handleCloseObserverPopup = () => {
    setPopupOpenObserver(false);
  };

  const handleCloseErrorPopUp = () => {
    setBackendError(false);
  };

  const open = Boolean(showModal);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setModalStatus(null);
  };

  const displayMileage = isDriveTime && appointment?.driveTimeMileage;

  const displayRMileage = isDriveTime && appointment?.reimbursableMileage;

  let address: any;

  if (appointment?.address !== null && appointment?.address !== undefined) {
    if (appointment?.location_type === APPT_LOCATION_TYPES.telehealth) {
      address = appointment?.link;
    } else {
      appointment?.location_type === APPT_LOCATION_TYPES.inclinic
        ? (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "addressLine2"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "))
        : (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "name" &&
                key !== "country" &&
                key !== "id"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
  }

  const openMaps = (event: any) => {
    event.stopPropagation();
    if (isAndroid) {
      const encodedAddress = encodeURIComponent(address);
      const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
      window.open(googleMapsUrl, "_blank");
    } else if (isIOS || isMacOs) {
      const encodedAddress = encodeURIComponent(address);
      const appleMapsUrl = `https://maps.apple.com/?q=${encodedAddress}`;
      window.open(appleMapsUrl, "_blank");
    } else if (isWindows) {
      const encodedAddress = encodeURIComponent(address);
      const bingMapsUrl = `https://www.bing.com/maps?q=${encodedAddress}`;
      window.open(bingMapsUrl, "_blank");
    } else {
      const encodedAddress = encodeURIComponent(address);
      const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  return (
    <>
      {loading && <CorticaLoader message="Please wait" />}
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleCloseErrorPopUp}
            heading="Appointment Edit or Cancel Operations are Disabled"
            paragraph="Currently, We are unable to check the conversion status of this appointment. This happens mostly due to CentralReach downtime. Please contact IT helpdesk if you face this issue for a long time."
          />
        </>
      )}
      <div className="appointment-wrapper" onClick={handleAppointmentOpenPopup}>
        {failed || !hasDriveTimeMileage ? (
          <div className="color-blue warning-red-alert"></div>
        ) : (
          <div className="color-blue"></div>
        )}
        <div className="appointment-content">
          {appointment.appointment_type === APPT_TYPES.admin ? (
            <>
              {isCancelled ? (
                <>
                  <div className="appt-row">
                    <span>
                      <del className="greyed-text deleted">
                        {appointment?.title}
                      </del>
                    </span>
                  </div>
                  <div className="appt-row">
                    <span>
                      <del className="greyed-text">
                        {`${formatDateAndTime(
                          appointment?.startAt
                        ).toLocaleLowerCase()} - ${formatDateAndTime(
                          appointment?.endAt
                        ).toLocaleLowerCase()}`}
                      </del>
                    </span>
                  </div>
                  {cancelLabel && (
                    <div className="appt-row">
                      {typeof cancelLabel === "string" ? (
                        <span>Reason: {cancelLabel}</span>
                      ) : (
                        <span>Reason: {cancelLabel?.label}</span>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isDriveTime ? (
                    <>
                      <div className="appt-row">{appointment?.title} </div>
                      <div className="appt-row">{`${formatDateAndTime(
                        appointment?.startAt
                      ).toLocaleLowerCase()} - ${formatDateAndTime(
                        appointment?.endAt
                      ).toLocaleLowerCase()}`}</div>
                      {hasDriveTimeMileage && (
                        <>
                          {" "}
                          <div className="appt-row">
                            Mileage:{" "}
                            {displayMileage &&
                              removeDecimalIfZero(
                                Number(appointment.driveTimeMileage)
                              )}
                            {displayMileage && " Miles"}
                          </div>
                          <div className="appt-row">
                            Reimbursable Mileage:{" "}
                            {displayRMileage &&
                              removeDecimalIfZero(
                                Number(appointment.reimbursableMileage)
                              )}
                            {displayMileage && " Miles"}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <span>
                      {appointment?.title && `${appointment?.title}, `}
                      {`${formatDateAndTime(
                        appointment?.startAt
                      ).toLocaleLowerCase()} - ${formatDateAndTime(
                        appointment?.endAt
                      ).toLocaleLowerCase()}`}
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {isCancelled ? (
                <>
                  <div className="appt-row">
                    <span className="greyed-text">
                      <del>
                        {appointment?.client_name}, {appointment?.title}
                      </del>
                    </span>
                  </div>
                  <div className="appt-row">
                    <span className="greyed-text">
                      <del>
                        {`${formatDateAndTime(
                          appointment?.startAt
                        ).toLocaleLowerCase()} - ${formatDateAndTime(
                          appointment?.endAt
                        ).toLocaleLowerCase()}`}
                      </del>
                    </span>
                  </div>
                  <div className="appt-row">
                    {foundReason?.label && (
                      <span>Reason: {foundReason?.label}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="appt-row">
                    {appointment.leadEventId === null && (
                      <span>
                        {appointment?.client_name}, {appointment?.title}
                      </span>
                    )}
                    {appointment.leadEventId != null && (
                      <span>
                        Observation: {appointment?.title},{" "}
                        {appointment?.client_name}
                      </span>
                    )}
                    <span className="appt-observer-eye">
                      {appointment?.observationsCount! > 0 ||
                      appointment?.leadEventId !== null ? (
                        <Eye className="appt-observer-eye-img" />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="appt-row">
                    <span>{`${formatDateAndTime(
                      appointment?.startAt
                    ).toLocaleLowerCase()} - ${formatDateAndTime(
                      appointment?.endAt
                    ).toLocaleLowerCase()}`}</span>
                  </div>
                  <div className="appt-row">
                    {appointment?.location_type ===
                    APPT_LOCATION_TYPES.offsite ? (
                      <span>{appointment?.location_category}</span>
                    ) : (
                      <span>{appointment?.location_type}</span>
                    )}
                  </div>
                  <div className="appt-row">
                    {appointment?.location_type ===
                    APPT_LOCATION_TYPES.telehealth ? (
                      <a
                        href={appointment.link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {appointment.link}
                      </a>
                    ) : appointment?.location_type ===
                      APPT_LOCATION_TYPES.inclinic ? (
                      <a
                        className="maps-address"
                        href="#"
                        onClick={(event) => openMaps(event)}
                      >
                        {address}
                      </a>
                    ) : (
                      <span>
                        {appointment?.address?.name === String_Initialiser ||
                        appointment?.address?.name === null ? (
                          <a
                            className="maps-address"
                            href="#"
                            onClick={(event) => openMaps(event)}
                          >
                            {address}
                          </a>
                        ) : (
                          <>
                            <span>{`${appointment?.address?.name} -`}</span>
                            <a
                              className="maps-address"
                              href="#"
                              onClick={(event) => openMaps(event)}
                            >
                              {` ${address}`}
                            </a>
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="menu-wrapper-abs">
          {showOption || softLoading || msgShow ? (
            <Popover
              id={id}
              open={open && showModal !== null}
              anchorEl={showModal}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="custom-menu" id="customModal">
                {showOption && !softLoading && !msgShow && (
                  <div>
                    <span
                      className="menu-option"
                      onClick={handleEditAppointment}
                    >
                      <span className={"menu-pointer"}>Update Appointment</span>
                    </span>
                    <div
                      className={`menu-option last ${
                        notLaterInTheDayAppointment ? "disabled-option" : ""
                      }`}
                      onClick={handleCancelAppointment}
                    >
                      <span className="menu-pointer">Cancel Appointment</span>
                    </div>
                  </div>
                )}

                {softLoading && (
                  <div className="menu-option last msg-text conv-span">
                    <span>Checking Conversion</span>
                  </div>
                )}

                {msgShow && (
                  <div className="menu-option msg-text last warn-msg">
                    <span>Appointment Converted</span>
                  </div>
                )}
              </div>
            </Popover>
          ) : null}
        </div>
        {statusText && (
          <div className="edit-here">
            {syncing ? (
              <div className="sync-status">
                <p>{statusText}</p>
              </div>
            ) : (
              <div className="normal-error">
                <p>{statusText}</p>
              </div>
            )}
          </div>
        )}
        {!failed && !isAbaAtSchool && !isPTO && (
          <div className="edit-here">
            <button
              onClick={handleClick}
              disabled={disableEdit || notTodayAppointment}
            >
              <FontAwesomeIcon className="icon-back" icon={faPen} />
            </button>
          </div>
        )}
        {failed && (
          <div className="edit-here-failed">
            <button
              disabled={resyncDisable}
              onClick={(event) => handleClickFailed(appointment, event)}
            >
              Re-Sync
            </button>
          </div>
        )}
      </div>
      <Popup
        isOpen={isPopupOpen}
        onClose={handleAppointmentClosePopup}
        heading="Appointment Failed to Sync"
        paragraph="The appointment has failed to sync to Central Reach. Please send a IT Help Desk ticket to report issue."
      />
      <Popup
        isOpen={isPopupOpenObserver}
        onClose={handleCloseObserverPopup}
        heading="Contact Scheduling to Update"
        paragraph="This appointment has been scheduled with a lead provider and observers. Please contact the Scheduling Team to modify the appointment."
      />
    </>
  );
};

export default React.memo(AppointmentComponent);
