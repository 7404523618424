import {
  AdminAppointmentForm,
  AdminAppointmentsList,
  DropdownOption,
  CreateApptPayload,
  Appointment,
  FormatTimeString,
  AddressInterface,
  Observation,
  CategorisedObservations,
  ObserverApptDates,
  LeadEvent,
} from "model/appointments";
import moment, { Moment } from "moment";
import {
  CR_SYNCING_CODES,
  CR_FAILURE_CODES,
  CR_CANCELLING_CODE,
  CR_CREATING_CODE,
  CR_UPDATING_CODE,
  CR_SUCCESS_CODES,
  ADMIN_APPOINTMENTS_ARRAY,
  DRIVETIME_APPT_IDS,
  ADMIN_APPT_TYPES_LABELS,
} from "constant/BiPortalConstants";

import {
  APPT_OPERATIONS,
  APPT_TYPES,
  String_Initialiser,
} from "constant/AppConstants";
import dayjs from "dayjs";
import { ApplicationStages } from "constant/AppConstants";

export const initValues = (apptList: DropdownOption[]) => {
  const momentDate = moment().format();

  return {
    selectedDay: momentDate,
    startDate: undefined,
    endDate: undefined,
    appointmentType: apptList[0].value,
    isFirstLastDrive: null,
  };
};

export const mapToValuedOption = (apptList: AdminAppointmentsList[]) => {
  const environment = process.env.REACT_APP_STAGE;
  return apptList?.map((appt) => {
    if (environment === ApplicationStages.local) {
      return {
        value: appt.dev.id,
        label: appt.value,
      };
    } else if (environment === ApplicationStages.dev) {
      return {
        value: appt.dev.id,
        label: appt.value,
      };
    } else if (environment === ApplicationStages.test) {
      return {
        value: appt.test.id,
        label: appt.value,
      };
    } else if (environment === ApplicationStages.uat) {
      return {
        value: appt.uat.id,
        label: appt.value,
      };
    } else {
      return {
        value: appt.prod.id,
        label: appt.value,
      };
    }
  });
};

export const getUtcTime = (time: string) => {
  const newDate = moment(new Date());
  const TimeHours = Number(time.split(":")[0]);
  const TimeMinutes = Number(time.split(":")[1]);
  const dateFormed = newDate
    .clone()
    .set("hours", TimeHours)
    .set("minutes", TimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);
  return dateFormed.toISOString();
};

export const adjustDateHours = (date: string, time: Moment) => {
  // const momentStartDate = moment.utc(date); // Convert date string to UTC
  // const momentStartTime = moment.utc(time); // Convert moment time to UTC

  const momentStartDate = moment(new Date(date));
  const momentStartTime = moment(time);

  const hourMinutes = momentStartTime.format("HH:mm");
  const hours = Number(hourMinutes.split(":")[0]);
  const minutes = Number(hourMinutes.split(":")[1]);

  const formedDate = momentStartDate
    .clone()
    .set("hours", hours)
    .set("minutes", minutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  return formedDate.toISOString();
};

export const getCreateAppointmentPayload = (
  payload: CreateApptPayload,
  formData: AdminAppointmentForm
) => {
  const startTimeBeforeFormating = moment(formData.startDate);
  const endTimeBeforeFormating = moment(formData.endDate);

  let formattedStartTime = startTimeBeforeFormating.format(" HH:mm");
  let formattedEndTime = endTimeBeforeFormating.format("HH:mm");

  const appointmentDate = moment(new Date(formData.selectedDay));

  const apptStartTimeHours = Number(formattedStartTime.split(":")[0]);
  const apptEndTimeHours = Number(formattedEndTime.split(":")[0]);
  const apptStartMinutes = Number(formattedStartTime.split(":")[1]);
  const apptEndTimeMinutes = Number(formattedEndTime.split(":")[1]);

  const newStartDate = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const newEndDate = appointmentDate
    .clone()
    .set("hours", apptEndTimeHours)
    .set("minutes", apptEndTimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const isDriveTime = DRIVETIME_APPT_IDS.includes(
    Number(formData.appointmentType)
  );

  const duration = moment.duration(newEndDate.diff(newStartDate)).asMinutes();

  return {
    startDate: newStartDate.toISOString(),
    endDate: newEndDate.toISOString(),
    providerId: payload.providerId,
    clinicId: payload.providerClinicId,
    appointmentTypeId: formData.appointmentType,
    duration: duration,
    providerName: payload.providerName,
    providerEmail: payload.providerEmail,
    note: formData.note,
    startingAddress: isDriveTime ? formData?.startingAddress : null,
    destinationAddress: isDriveTime ? formData?.destinationAddress : null,
    miles: isDriveTime ? formData?.miles : null,
    reimburseableMiles: isDriveTime
      ? formData?.isFirstLastDrive
        ? formData?.reimburseableMiles
        : formData?.miles
      : null,
    isLastDrive: isDriveTime ? formData?.isFirstLastDrive : null,
    isDriveTime: isDriveTime,
    providerClinicTimeZone: payload.providerClinicTimeZone
  };
};

export const nullAddressCheck = (address: AddressInterface) => {
  if (
    address.freeformAddress === String_Initialiser ||
    address.lat === String_Initialiser
  ) {
    return false;
  } else {
    return true;
  }
};

export const addDriveTimeSpecificPayload = (payload: any, formData: any) => {
  return {
    ...payload,
    startingAddress: formData?.startingAddress,
    destinationAddress: formData?.destinationAddress,
    miles: formData?.miles,
    reimburseableMiles: formData?.reimburseableMiles,
    isLastDrive: formData?.isFirstLastDrive,
    isDriveTime: true,
  };
};

export const getObserverPayload = (
  modifiedDates: ObserverApptDates,
  leadEvent: LeadEvent,
  observerEventId: number,
  providerName: string,
  type: string
) => {
  const modifiedObservations =
    leadEvent.observations
      ?.filter((event) => {
        if (type === APPT_OPERATIONS.cancel && event.id === observerEventId) {
          return false;
        }
        return true;
      })
      ?.map((event) => {
        if (event.id === observerEventId && type === APPT_OPERATIONS.edit) {
          const startDate = new Date(modifiedDates.startDate);
          const endDate = new Date(modifiedDates.endDate);
          const duration = Math.round(
            (endDate.getTime() - startDate.getTime()) / (1000 * 60)
          );

          return {
            id: event.id,
            startDate: modifiedDates.startDate,
            endDate: modifiedDates.endDate,
            duration: duration,
            locationType: event.locationType,
            telehealthLink: event.telehealthLink,
            provider: { id: event.provider?.id },
          };
        }

        return {
          id: event.id,
          startDate: event.startDate,
          endDate: event.endDate,
          duration: event.duration,
          locationType: event.locationType,
          telehealthLink: event.telehealthLink,
          provider: { id: event.provider?.id },
        };
      }) || [];

  return {
    leadEventId: leadEvent.id,
    currentObservers: modifiedObservations,
    CreatedBy: `${providerName} from Axon ABA`,
    cancelNote: `${providerName} from Axon ABA`,
  };
};

export const cancelAppointmentPayload = (
  payload: CreateApptPayload,
  cancelReason: string,
  displayCancelReason: string,
  appointment: Appointment,
  note?: string
) => {
  let adminDirectAppointmentId;
  const environment = process.env.REACT_APP_STAGE || "prod";
  const adminDirectAppointmentConfig: any = ADMIN_APPOINTMENTS_ARRAY.find(
    (appointment) => appointment.value === ADMIN_APPT_TYPES_LABELS.adminDirect
  );
  if (adminDirectAppointmentConfig && environment !== "local") {
    adminDirectAppointmentId = adminDirectAppointmentConfig[environment].id;
  } else {
    adminDirectAppointmentId = 1;
  }

  return {
    eventId: appointment.id,
    leadEventId: payload.leadEventId,
    providerId: appointment.provider_id,
    cancelNote: note ? note : "Cancelling from BI Application",
    providerName: payload.providerName,
    providerEmail: payload.providerEmail,
    cancelReasonId: cancelReason,
    displayCancelReason: displayCancelReason,
    appointmentType: payload.appointmentType,
    startDate: payload.startDate,
    endDate: payload.endDate,
    duration: moment
      .duration(moment(payload.endDate).diff(moment(payload.startDate)))
      .asMinutes(),
    clinicId: payload.clinicId,
    adminDirectAppointmentId: adminDirectAppointmentId,
    observations: appointment?.observations?.length
      ? appointment?.observations
      : undefined,
    providerClinicTimeZone: payload.providerClinicTimeZone,

  };
};

export const getTimeFromIso = (start: string, end: string) => {
  const startTime = moment(new Date(start)).format("hh:mm A");
  const endTime = moment(new Date(end)).format("hh:mm A");
  return {
    startTime: startTime,
    endTime: endTime,
  };
};

export const getTimeFromStrings = (formData: FormatTimeString) => {
  const appointmentDate = moment(formData.startTime);

  const startTimeBeforeFormating = moment(formData.startTime);
  const endTimeBeforeFormating = moment(formData.endTime);

  let formattedStartTime = startTimeBeforeFormating.format("HH:mm");
  let formattedEndTime = endTimeBeforeFormating.format("HH:mm");

  const [hours, minutes] = formattedStartTime.split(":");
  const [endhours, endminutes] = formattedEndTime.split(":");

  const apptStartTimeHours = Number(hours);

  const apptEndTimeHours = Number(endhours);

  const apptStartMinutes = Number(minutes);

  const apptEndTimeMinutes = Number(endminutes);

  // ------------------

  const newStartDate = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const newEndDate = appointmentDate
    .clone()
    .set("hours", apptEndTimeHours)
    .set("minutes", apptEndTimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  return {
    startTime: newStartDate.format("hh:mm a"),
    endTime: newEndDate.format("hh:mm a"),
  };
};

export const checkIfItisToday = (date: string) => {
  const timestamp = new Date(date);

  const currentDate = new Date();

  if (
    timestamp.getFullYear() === currentDate.getFullYear() &&
    timestamp.getMonth() === currentDate.getMonth() &&
    timestamp.getDate() === currentDate.getDate()
  ) {
    return false;
  } else if (timestamp < currentDate) {
    return false;
  } else {
    return true;
  }
};

export const getCrStatusString = (
  code: number,
  isCancelling?: boolean,
  isHardCancelling?: boolean
) => {
  if (CR_FAILURE_CODES.includes(code)) {
    return "Failed";
  } else if (code === CR_CREATING_CODE) {
    return "Creating";
  } else if (code === CR_UPDATING_CODE && !isHardCancelling) {
    return "Updating";
  } else if (isHardCancelling) {
    return "Cancelling";
  } else if (
    CR_CANCELLING_CODE.includes(code) ||
    (isCancelling && !CR_SUCCESS_CODES.includes(code))
  ) {
    return "Canceling";
  } else {
    return;
  }
};

export const syncingStrings = [];

export const isEventSyncing = (
  code: number,
  isCancelling?: boolean,
  isHardCancelling?: boolean
) => {
  if (
    CR_CANCELLING_CODE.includes(code) ||
    CR_SYNCING_CODES.includes(code) ||
    isCancelling ||
    isHardCancelling
  ) {
    return true;
  } else {
    return false;
  }
};

export const isFailedToSync = (code: number) => {
  if (CR_FAILURE_CODES.includes(code)) {
    return true;
  } else {
    return false;
  }
};

export const clientFailedToSync = (code: number) => {
  if (CR_FAILURE_CODES.includes(code) || CR_SYNCING_CODES.includes(code)) {
    return true;
  } else {
    return false;
  }
};

export const clientAppointmentSyncing = (code: number) => {
  if (CR_SYNCING_CODES.includes(code)) {
    return true;
  } else {
    return false;
  }
};

export const getFormattedHHmm = (hour: string | number) => {
  const formattedHour = hour < 10 ? `0${hour}` : hour.toString();
  return formattedHour;
};

export const getEditAppointmentInitValues = (appointment: Appointment) => {
  const isAdmin = appointment.appointment_type === APPT_TYPES.admin;
  const isDriveTime = appointment.title === ADMIN_APPT_TYPES_LABELS.driveTime;
  const isMileageOnly =
    appointment.title === ADMIN_APPT_TYPES_LABELS.mileageOnly;

  const startDate = new Date(appointment.startAt);
  const endDate = new Date(appointment.endAt);
  const startHours = dayjs(startDate);

  const endHours = dayjs(endDate);

  const commonApptData = {
    origialStart: startHours,
    originalEnd: endHours,
    startTime: startHours,
    endTime: endHours,
    note: appointment.note?.note,
  };

  const adminSpecificValues = {
    appointmentType: appointment?.type_id,
    isFirstLastDrive:
      isDriveTime || isMileageOnly
        ? appointment?.isFirstOrLastDriveToNonClinic
        : null,
  };

  if (isAdmin) {
    return { ...commonApptData, ...adminSpecificValues };
  } else {
    return commonApptData;
  }
};

export const getObserverAppointmentInitValues = (
  appointment: LeadEvent,
  apptStart: string,
  apptEnd: string
) => {
  return {
    originalStart: dayjs(new Date(appointment.startDate!)),
    originalEnd: dayjs(new Date(appointment.endDate!)),
    startTime: dayjs(new Date(apptStart)),
    endTime: dayjs(new Date(apptEnd)),
  };
};

export const compareTimes = (time1: any, time2utc: string, odd?: boolean) => {
  if (odd) {
    return time1.toISOString() === time2utc ? true : false;
  } else {
    return time1.toISOString() === time2utc ? false : true;
  }
};

export const isIsoStringGreaterThan = (date1: Date, date2: Date): boolean => {
  // Use the getTime method to compare dates
  return date1.getTime() > date2.getTime();
};

export const getTimeUtcString = (date: string, time: any) => {
  const appointmentDate = moment(new Date(date));
  const apptStartTimeHours = Number(time.format("HH:mm").split(":")[0]);
  const apptStartMinutes = Number(time.format("HH:mm").split(":")[1]);
  const newDateStirng = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  return newDateStirng.toISOString();
};

export const GetUpdateCoursePayload = (
  appointment: Appointment,
  isAdmin: boolean,
  email: string,
  formData: any,
  observationEvents?: CategorisedObservations
) => {
  const appointmentDate = moment(new Date(appointment.startAt));

  const apptStartTimeHours = Number(
    formData.startDate.format("HH:mm").split(":")[0]
  );
  const apptEndTimeHours = Number(
    formData.endDate.format("HH:mm").split(":")[0]
  );
  const apptStartMinutes = Number(
    formData.startDate.format("HH:mm").split(":")[1]
  );
  const apptEndTimeMinutes = Number(
    formData.endDate.format("HH:mm").split(":")[1]
  );

  const newStartDate = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const newEndDate = appointmentDate
    .clone()
    .set("hours", apptEndTimeHours)
    .set("minutes", apptEndTimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const isDriveTime =
    appointment.title === ADMIN_APPT_TYPES_LABELS.driveTime ||
    appointment.title === ADMIN_APPT_TYPES_LABELS.mileageOnly;

  const isAdminNPole = isAdmin ? false : true;

  const hasStartChanged = compareTimes(formData.startDate, appointment.start);

  const hasEndChanged = compareTimes(formData.endDate, appointment.end);

  const duration = moment.duration(newEndDate.diff(newStartDate)).asMinutes();

  const result = {
    eventId: appointment.id,
    clinicPreference: appointment.clinicPreference,
    isPendingConfirmation: false,
    originalStartDate: appointment.start,
    originalEndDate: appointment.end,
    startDate: newStartDate.toISOString(),
    endDate: newEndDate.toISOString(),
    duration: duration,
    editNote: `Patient Time Adjustment from Bi Portal by ${appointment.provider_name}`,
    locationType: isAdmin ? null : appointment.location_type,
    paymentMethod: isAdmin ? null : appointment.paymentMethod,
    locationCategory: appointment.location_category,
    appointmentTypeId: appointment.type_id,
    providerId: appointment.provider_id,
    clientId: isAdmin ? null : appointment.client_id,
    clinicId: appointment.clinic.id,
    clinicName: appointment.clinic.name,
    clientName: isAdmin ? null : appointment.client_name,
    isAdmin: isAdmin,
    appointmentName: appointment.type_name,
    cancelNote: "Cancel from Bi Portal",
    providerName: appointment.provider_name,
    providerEmail: email,
    reason: isAdmin && formData?.reason ? formData.reason : null,
    startReason: isAdminNPole && hasStartChanged ? formData.startReason : null,
    endReason: isAdminNPole && hasEndChanged ? formData.endReason : null,
    address: appointment.address,
    note: { id: appointment?.note?.id, note: formData.note },
    telehealthLink:
      isAdmin || appointment.location_type !== "Telehealth"
        ? null
        : appointment?.telehealthLink,
    startingAddress: isDriveTime ? formData?.startingAddress : null,
    destinationAddress: isDriveTime ? formData?.destinationAddress : null,
    miles: isDriveTime ? formData?.miles.toString() : null,
    reimburseableMiles: isDriveTime
      ? formData?.isFirstLastDrive
        ? formData?.reimburseableMiles
        : formData?.miles
      : null,
    observations:
      isAdminNPole && observationEvents
        ? [
            ...observationEvents.unaffectedEvents,
            ...observationEvents.timeChangedEvents,
          ]
        : null,
    affectedObservations:
      isAdminNPole && observationEvents
        ? {
            timeChangedEvents: observationEvents.timeChangedEvents,
            canceledEvents: observationEvents.canceledEvents,
          }
        : null,
    isLastDrive: isDriveTime ? formData?.isFirstLastDrive : null,
    isDriveTime: isDriveTime,
    providerClinicTimeZone: appointment.clinic.timezone,
  };

  return result;
};

export const formatObservations = (
  affectedObservations: CategorisedObservations
): { rescheduled: string; canceled: string } => {
  const { timeChangedEvents, canceledEvents } = affectedObservations;

  const formatTime = (utcString: string) => {
    const date = new Date(utcString);
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const timeChangedFormatted = timeChangedEvents
    .map(
      (observation) =>
        `${observation.provider?.firstName} : (${formatTime(
          observation.startDate
        )} - ${formatTime(observation.endDate)})`
    )
    .join(", ");

  const canceledFormatted = canceledEvents
    .map(
      (observation) =>
        `${observation.provider?.firstName} : (${formatTime(
          observation.startDate
        )} - ${formatTime(observation.endDate)})`
    )
    .join(", ");

  return {
    rescheduled: timeChangedFormatted,
    canceled: canceledFormatted,
  };
};

export const changeEventFrame = (
  event: Appointment,
  newStartTime: string,
  newEndTime: string
): Appointment => {
  const apptDuration =
    new Date(event.endAt).getTime() - new Date(event.startAt).getTime();
  const newStart = new Date(newStartTime).getTime();
  const newEnd = new Date(newEndTime).getTime();

  const startDiff = new Date(event.startAt).getTime() - newStart;
  const endDiff = new Date(event.endAt).getTime() - newEnd;

  if (startDiff > apptDuration && endDiff > apptDuration) {
    const timeOffset = newStart - new Date(event.startAt).getTime();

    const updatedStartAt = new Date(
      new Date(event.startAt).getTime() + timeOffset
    ).toISOString();
    const updatedEndAt = new Date(
      new Date(event.endAt).getTime() + timeOffset
    ).toISOString();

    const updatedEvent = {
      ...event,
      startAt: updatedStartAt,
      endAt: updatedEndAt,
      observations:
        event.observations?.map((observation) => {
          const updatedStartDate = new Date(
            new Date(observation.startDate).getTime() + timeOffset
          ).toISOString();
          const updatedEndDate = new Date(
            new Date(observation.endDate).getTime() + timeOffset
          ).toISOString();

          return {
            ...observation,
            startDate: updatedStartDate,
            endDate: updatedEndDate,
          };
        }) || [],
    };

    return updatedEvent;
  } else {
    return event;
  }
};

export const classifyObservations = (
  event: Appointment,
  newStartTime: string,
  newEndTime: string
) => {
  const unaffectedEvents: Observation[] = [];
  const timeChangedEvents: Observation[] = [];
  const canceledEvents: Observation[] = [];

  const eventStartTime = new Date(event.startAt);
  const eventEndTime = new Date(event.endAt);
  const newEventStartTime = new Date(newStartTime);
  const newEventEndTime = new Date(newEndTime);

  event.observations!.forEach((observation) => {
    let observationStartTime = new Date(observation.startDate);
    let observationEndTime = new Date(observation.endDate);

    const eventStartTimeMs = eventStartTime.getTime();
    const eventEndTimeMs = eventEndTime.getTime();
    const newEventStartTimeMs = newEventStartTime.getTime();
    const newEventEndTimeMs = newEventEndTime.getTime();

    if (
      observationStartTime >= newEventStartTime &&
      observationEndTime <= newEventEndTime
    ) {
      unaffectedEvents.push(observation);
    } else if (
      newEventStartTimeMs !== eventStartTimeMs &&
      newEventEndTimeMs === eventEndTimeMs
    ) {
      const t1offset = newEventStartTimeMs - observationStartTime.getTime();

      observationStartTime = new Date(newEventStartTimeMs);

      observationEndTime.setTime(observationEndTime.getTime() + t1offset);

      if (observationEndTime > newEventEndTime) {
        observationEndTime = newEventEndTime;
      }

      timeChangedEvents.push({
        ...observation,
        startDate: observationStartTime.toISOString(),
        endDate: observationEndTime.toISOString(),
        duration:
          (observationEndTime.getTime() - observationStartTime.getTime()) /
          (1000 * 60),
      });
    } else if (
      newEventStartTimeMs === eventStartTimeMs &&
      newEventEndTimeMs !== eventEndTimeMs
    ) {
      const t2offset = newEventEndTimeMs - observationEndTime.getTime();

      observationEndTime = newEventEndTime;

      observationStartTime.setTime(observationStartTime.getTime() + t2offset);

      if (observationStartTime < newEventStartTime) {
        observationStartTime = newEventStartTime;
      }

      timeChangedEvents.push({
        ...observation,
        startDate: observationStartTime.toISOString(),
        endDate: observationEndTime.toISOString(),
        duration:
          (observationEndTime.getTime() - observationStartTime.getTime()) /
          (1000 * 60),
      });
    } else if (
      newEventStartTimeMs !== eventStartTimeMs &&
      newEventEndTimeMs !== eventEndTimeMs
    ) {
      if (observationStartTime < newEventStartTime) {
        const t1offset = newEventStartTimeMs - observationStartTime.getTime();

        observationStartTime = new Date(newEventStartTimeMs);

        observationEndTime.setTime(observationEndTime.getTime() + t1offset);

        if (observationEndTime > newEventEndTime) {
          observationEndTime = newEventEndTime;
        }

        timeChangedEvents.push({
          ...observation,
          startDate: observationStartTime.toISOString(),
          endDate: observationEndTime.toISOString(),
          duration:
            (observationEndTime.getTime() - observationStartTime.getTime()) /
            (1000 * 60),
        });
      } else if (observationEndTime > newEventEndTime) {
        const t2offset = newEventEndTimeMs - observationEndTime.getTime();

        observationEndTime = newEventEndTime;

        observationStartTime.setTime(observationStartTime.getTime() + t2offset);

        if (observationStartTime < newEventStartTime) {
          observationStartTime = newEventStartTime;
        }

        timeChangedEvents.push({
          ...observation,
          startDate: observationStartTime.toISOString(),
          endDate: observationEndTime.toISOString(),
          duration:
            (observationEndTime.getTime() - observationStartTime.getTime()) /
            (1000 * 60),
        });
      }
    } else {
      unaffectedEvents.push(observation);
    }
  });

  return {
    unaffectedEvents,
    timeChangedEvents,
    canceledEvents,
  };
};
