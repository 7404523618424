import { Appointment, LeadEvent } from "model/appointments";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CustomAppointmentWrapper } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CANCELLATION_REASONS } from "constant/BiPortalConstants";
import { CANCEL_APPOINTMENT_SCHEMA } from "../../../../utils/BIPortal/Validators/appointmets";
import ConfirmCancellation from "./ConfirmCancellation";
import { FormItem } from "../../../../sharedComponents/reactHookComponents/formItem";
import { Select } from "sharedComponents/reactHookComponents";

import { Controller, FormProvider } from "react-hook-form";
import { CancelDropdownOptions } from "model/appointments";
import CrBannerError from "sharedComponents/Error/crBannerError";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import { TextAreaField } from "sharedComponents/reactHookComponents/textfield";

interface LocationState {
  appointment: Appointment;
  activeDay?: string;
  leadEvent?: LeadEvent;
}

const CancelAppointment: React.FC = () => {
  const { webSocketData } = useWebSocket();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const appointmentInfo = location.state.appointment;
  const leadEvent = location.state.leadEvent;
  const activeDay = location.state.activeDay;
  const [isFormVisible, setVisible] = useState(true);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [headerTitle, setHeaderTitle] = useState<string>("Cancel Appointment");
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const cancellationReason: CancelDropdownOptions[] = CANCELLATION_REASONS;
  //form code
  const methods = useForm<any>({
    resolver: yupResolver(CANCEL_APPOINTMENT_SCHEMA),
    mode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: "onChange",
  });

  const reasonHandler = React.useCallback(
    (val: number) => {
      methods.setValue("cancellationId", val);
    },
    [methods]
  );

  const submitForm = React.useCallback(() => {
    setVisible(false);
    setHeaderTitle("Confirm Cancellation");
  }, []);
  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  const handleBackClick = () => {
    if (isFormVisible || appointmentInfo.leadEventId) {
      history.push({
        pathname: "/providerappointments",
        state: {
          prefStartDate: activeDay,
        }, // New route
      });
    } else {
      setHeaderTitle("Cancel Appointment");
      setVisible(true);
    }
  };

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData?.athena !== true || webSocketData?.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  useEffect(() => {
    if (isFormVisible) {
      setShowConfirm(false);
    } else {
      setShowConfirm(true);
    }
  }, [isFormVisible]);

  useEffect(() => {
    if (appointmentInfo.leadEventId) {
      setShowConfirm(true);
      setVisible(false);
    }
  }, [appointmentInfo]);

  return (
    <CustomAppointmentWrapper>
      <div className="w-100 d-flex h-center">
        <div className="header-div">
          <div className="back-to h-end v-center">
            <button onClick={handleBackClick}>
              <FontAwesomeIcon className="icon-back" icon={faChevronLeft} />
            </button>
          </div>
          <div className="page-desc h-center v-center">
            <span>{headerTitle}</span>
          </div>
          <div className="back-to"></div>
        </div>
      </div>

      {displayBanner && (
        <div>
          <CrBannerError></CrBannerError>
        </div>
      )}

      <FormProvider {...methods}>
        <div className={isFormVisible ? "form-enclosure max-span" : "d-none"}>
          <div className="main-form">
            <div className="form-row antd-100 h-center">
              <FormItem optional={false} label="Cancellation Reason">
                <Controller
                  name="cancellationId"
                  control={methods.control}
                  render={({ field }) => (
                    <>
                      <Select
                        name="cancellationId"
                        placeholder="Select a reason"
                        options={cancellationReason}
                        field={field}
                        errors={methods.formState.errors.cancellationId}
                        onChange={reasonHandler}
                      />
                    </>
                  )}
                />
              </FormItem>
            </div>

            <div className="form-row ant-col-100 txt-area">
              <FormItem optional={false} label="Note (Optional)">
                <Controller
                  name="note" // Update controller name to "note"
                  control={methods.control}
                  render={({ field }) => (
                    <TextAreaField // Replace DesktopTimePicker with TextAreaField
                      field={field}
                      errors={methods?.formState?.errors?.note}
                      rows={4} // Optionally specify rows for the text area
                      placeholder="Add cancelation notes here :)"
                    />
                  )}
                />
              </FormItem>
            </div>

            <div className="submit-button">
              <button
                type="submit"
                onClick={methods.handleSubmit(submitForm, submitError)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {showConfirm && (
          // <></>
          <ConfirmCancellation
            providerId={appointmentInfo.provider_id}
            providerClinicId={appointmentInfo.clinic.id}
            providerName={appointmentInfo.provider_name}
            appointment={appointmentInfo}
            leadEventId={appointmentInfo.leadEventId}
            leadEvent={leadEvent}
            providerClinicTimeZone={appointmentInfo.provider_clinic_timezone}
          ></ConfirmCancellation>
        )}
      </FormProvider>
    </CustomAppointmentWrapper>
  );
};

export default React.memo(CancelAppointment);
