import React from "react";
import { useLocation } from "react-router-dom";
import NewAppointmentForm from "./NewAppointmentForm";
import "../globalBiPortalStyle.scss";
import { useHistory } from "react-router-dom";

const NewProviderAppointment = () => {
  const history = useHistory();
  const location = useLocation();

  if (location.state) {
    const {
      providerId,
      providerName,
      providerClinicId,
      providerEmail,
      prevDate,
      providerClinicTimeZone
    } = location.state;

    return (
      <div>
        <NewAppointmentForm
          providerId={providerId}
          clinicId={providerClinicId}
          providerName={providerName}
          providerEmail={providerEmail}
          prevDate={prevDate}
          providerClinicTimeZone={providerClinicTimeZone}
        ></NewAppointmentForm>
      </div>
    );
  } else {
    if (location.state) {
      const {
        providerId,
        providerName,
        providerClinicId,
        providerEmail,
        prevDate,
        providerClinicTimeZone
      } = location.state;

      return (
        <div>
          <NewAppointmentForm
            providerId={providerId}
            clinicId={providerClinicId}
            providerName={providerName}
            providerEmail={providerEmail}
            prevDate={prevDate}
            providerClinicTimeZone={providerClinicTimeZone}
          ></NewAppointmentForm>
        </div>
      );
    } else {
      const homeRedirection = () => {
        history.push({
          pathname: "/providerappointments", // New route
        });
      };

      return (
        <div className="fd-column">
          <div className="w-100 h-center mr-50 txt-center">
            <p>
              We don't have enough data at the moment to proceed with
              Appointment create operation. Please start over to proceed
            </p>
          </div>
          <div className="back-btn">
            <button onClick={() => homeRedirection()}>Start Over</button>
          </div>
        </div>
      );
    }
  }
};

export default React.memo(NewProviderAppointment);
