import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getCreateAppointmentPayload } from "./appointments.helper";
import { ConfirmAppointmentWrapper } from "./style";
import {
  ADMIN_APPOINTMENTS_ARRAY,
  DRIVETIME_APPT_IDS,
} from "constant/BiPortalConstants";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCar,
  faClock,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { getTimeFromStrings } from "./appointments.helper";
import { ApiCalls } from "./AllAppointmentsApi";
import Popup from "sharedComponents/reactHookComponents/popup/popup";
import { AddressInterface } from "model/appointments";
import { ApplicationStages } from "constant/AppConstants";
import { useSubmitApi } from "customHooks/authHook";
import CorticaLoader from "sharedComponents/CorticaLoader/CorticaLoader";
import { LOADING_LABELS } from "constant/BiPortalConstants";

interface Props {
  providerId: number;
  providerClinicId: number;
  providerName: string;
  providerEmail: string;
  startingAddress?: AddressInterface;
  destinationAddress?: AddressInterface;
  providerClinicTimeZone: string;
}

const ConfirmAppointment: React.FC<Props> = ({
  providerId,
  providerClinicId,
  providerName,
  providerEmail,
  startingAddress,
  destinationAddress,
  providerClinicTimeZone
}) => {
  const methods = useFormContext();
  const { submitApiCall } = useSubmitApi();
  const history = useHistory();
  const [softloading, setsoftloading] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const getExtractedMethodsData = () => {
    return {
      startDate: methods.getValues("startDate"),
      endDate: methods.getValues("endDate"),
      appointmentType: methods.getValues("appointmentType"),
      selectedDay: methods.getValues("selectedDay"),
      startingAddress: startingAddress,
      destinationAddress: destinationAddress,
      miles: methods.getValues("miles"),
      reimburseableMiles: methods.getValues("reimburseableMiles"),
      isFirstLastDrive: methods.getValues("isFirstLastDrive"),
      note: methods.getValues("note"),
    };
  };

  const formData = getExtractedMethodsData();

  const selectedAppointment = ADMIN_APPOINTMENTS_ARRAY.filter(function (appt) {
    const environment = process.env.REACT_APP_STAGE;
    if (environment === ApplicationStages.local) {
      return appt.dev.id === formData.appointmentType;
    } else if (environment === ApplicationStages.dev) {
      return appt.dev.id === formData.appointmentType;
    } else if (environment === ApplicationStages.test) {
      return appt.test.id === formData.appointmentType;
    } else if (environment === ApplicationStages.uat) {
      return appt.uat.id === formData.appointmentType;
    } else {
      return appt.prod.id === formData.appointmentType;
    }
  })[0];

  const displayDate = moment(new Date(methods.getValues("selectedDay"))).format(
    " MMMM DD, YYYY"
  );

  const isDriveTime = DRIVETIME_APPT_IDS.includes(
    Number(formData.appointmentType)
  );

  const startAddr = isDriveTime ? methods.getValues("startingAddress") : null;

  const destAddr = isDriveTime ? methods.getValues("destinationAddress") : null;

  const mileAge = isDriveTime ? methods.getValues("miles") : null;

  const reimbursableMileage = isDriveTime
    ? methods.getValues("reimburseableMiles")
    : null;

  const note = methods.getValues("note");

  const payload = {
    providerId: providerId,
    providerClinicId: providerClinicId,
    providerName: providerName,
    providerEmail: providerEmail,
    providerClinicTimeZone: providerClinicTimeZone
  };
  const payloadData = getCreateAppointmentPayload(payload, formData);

  const displayTime = getTimeFromStrings({
    selectedDate: formData.selectedDay,
    startTime: formData.startDate,
    endTime: formData.endDate,
  });

  const homeRedirection = () => {
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: payloadData.startDate,
      },
    });
  };

  async function addAppointments(payload: any) {
    setsoftloading(true);
    setBackendError(false);

    try {
      await submitApiCall(ApiCalls.ConfirmAddCall, payload);
      setsoftloading(false);
      homeRedirection();
    } catch (error) {
      console.log("[Provider] [AddAppointment] error", error);
      setsoftloading(false);
      // homeRedirection();
      setBackendError(true);
    }
  }

  const submitData = () => {
    addAppointments(payloadData);
  };

  const handleClosePopUp = () => {
    setBackendError(false);
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: payloadData.startDate,
      },
    });
  };

  return (
    <ConfirmAppointmentWrapper>
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleClosePopUp}
            heading={"Sorry, That didn't Happen Properly"}
            paragraph={
              "Error happened during creating the appointment. Please try again after a few minutes. Check if the appointment is created. If the problem persists, Please contact IT support team"
            }
          />
        </>
      )}
      <div className="wrapper-container">
        {softloading && (
          <CorticaLoader message={LOADING_LABELS.createAppt}></CorticaLoader>
        )}
        {!softloading && (
          <div className="info-content-div w-100">
            <div className="span-block">
              <span>{selectedAppointment.value}</span>
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faCalendar} />
              </span>
              <span>{displayDate}</span>
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faClock} />
              </span>
              <span>
                {displayTime.startTime} - {displayTime.endTime}
              </span>
            </div>
            {isDriveTime && (
              <>
                <div className="span-block txt-center">
                  <span>
                    <span className="icon-span">
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faLocationArrow}
                      />
                    </span>
                    <span className="bolder-custom-span margin-r-2">
                      Start Destination:
                    </span>
                    {startAddr}
                  </span>
                </div>
                <div className="span-block txt-center">
                  <span>
                    <span className="icon-span">
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faLocationArrow}
                      />
                    </span>
                    <span className="bolder-custom-span margin-r-2">
                      End Destination:
                    </span>
                    {destAddr}
                  </span>
                </div>
                <div className="span-block">
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faCar} />
                  </span>
                  <span>
                    <span className="bolder-custom-span margin-r-2">
                      Mileage:
                    </span>
                    {mileAge} miles
                  </span>
                </div>
                <div className="span-block">
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faCar} />
                  </span>
                  <span>
                    <span className="bolder-custom-span margin-r-2">
                      Reimbursable Mileage:
                    </span>
                    {reimbursableMileage ? reimbursableMileage : 0} miles
                  </span>
                </div>
              </>
            )}
            {note && (
              <div className="span-block">
                <span>
                  <span className="bolder-custom-span margin-r-2">Note:</span>
                  {note}
                </span>
              </div>
            )}
            <div className="submit-button">
              <button onClick={submitData}>Confirm</button>
            </div>
          </div>
        )}
      </div>
    </ConfirmAppointmentWrapper>
  );
};

export default React.memo(ConfirmAppointment);
