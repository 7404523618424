import React, { useCallback, useContext, useEffect } from "react";
import "./clientRescheduleConfirm.scss";
import calender from "assets/CalendarBlank.svg";
import RescheduleMapPin from "assets/RescheduleMapPin.svg";
import RescheduleClock from "assets/RescheduleClock.svg";
import Rescheduleuser from "assets/RescheduleUser.svg";
import { Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { appointments } from "model/calender/appointments";
import moment from "moment";
import { myContext } from "features/Hooks/useContext";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { clientRescheduleAndCancelCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { useAuth0 } from "react-auth0-spa";
import { addressData, DiffMinute } from "./clientReschedule.helper";
import {
  ClientRescheduleDropdown,
  default_Dropdown,
  CustomSelectOption,
  error_msg,
  error_504_status,
} from "./staticRescheduleData";
import { IAddress } from "model/appointments";
import Spinner from "features/Appointments/Spinner/spinner";
const momentTz = require("moment-timezone");

interface LocationState {
  appointment: appointments;
  activeDay: string;
  path: string;
  payload: any;
  flow: string;
}
interface ContextType {
  state: any;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  validateUrl: Boolean;
  setState: React.Dispatch<React.SetStateAction<string>>;
  confirmLoading: Boolean;
  setConfirmLoading: React.Dispatch<React.SetStateAction<Boolean>>;
}

const ClientRescheduleConfirm: React.FC<{}> = () => {
  const { state, validateUrl, setState, confirmLoading, setConfirmLoading } =
    useContext<ContextType>(myContext);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { getTokenSilently } = useAuth0();
  const { appointment, activeDay, path, payload, flow } = location.state;

  const {
    rcSuggestions,
    selectedDate,
    newStartTime,
    newEndTime,
    dropdown,
    textArea,
  } = state;

  console.log(
    rcSuggestions,
    selectedDate,
    appointment,
    newStartTime,
    newEndTime,
    "[reschedule confirm] state Data"
  );

  const newTime = `${momentTz
    .tz(newStartTime, selectedDate?.clientTimezone)
    .format("hh:mm a")} - ${momentTz
    .tz(newEndTime, selectedDate?.clientTimezone)
    .format("hh:mm a")}`;

  let address: any;

  if (appointment?.address !== null && appointment?.address !== undefined) {
    if (appointment?.location_type === "Telehealth") {
      address = appointment?.link;
    } else {
      appointment?.location_type === "In Clinic"
        ? (address = `${appointment.clinic.name} Clinic`)
        : (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "name" &&
                key !== "country" &&
                key !== "id"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
  }

  const prevAppointmentDetails: any = [
    {
      src: calender,
      alt: "calendar",
      text: moment(appointment?.start).format("dddd MMMM D, YYYY"),
    },
    {
      src: RescheduleClock,
      alt: "clock",
      text: `${moment(appointment?.start).format("h:mm a")} - ${moment(
        appointment?.end
      ).format("h:mm a")}`,
    },
    {
      src: Rescheduleuser,
      alt: "User",
      text: `${appointment.provider.split(", ").join(" ")}, ${
        selectedDate?.provider?.speciality?.abbreviation
      }`,
    },
    {
      src: RescheduleMapPin,
      alt: "pin",
      text: address,
    },
  ];

  const nextAppointmentDetails: any = [
    {
      src: calender,
      alt: "calendar",
      text: moment(selectedDate?.date).format("dddd MMMM D, YYYY"),
    },
    {
      src: RescheduleClock,
      alt: "clock",
      text: newTime,
    },
    {
      src: Rescheduleuser,
      alt: "User",
      text: `${selectedDate?.provider?.displayName.split(", ").join(" ")}, ${
        selectedDate?.provider?.speciality?.abbreviation
      }`,
    },
    {
      src: RescheduleMapPin,
      alt: "pin",
      text: address,
    },
  ];

  const handleNavReschedule = () => {
    history.push({
      pathname: "/client-reschedule-appointment",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
        flow: flow,
      },
    });
  };

  const duration = DiffMinute(newStartTime, newEndTime);

  const rescheduleAddress = addressData(appointment);

  const selectedOption = ClientRescheduleDropdown?.find(
    (option: CustomSelectOption) => option.value === dropdown
  );

  const LabelData = selectedOption?.label;
  const keyData = selectedOption?.key;

  const handleConfirmReschedule = useCallback(async () => {
    setConfirmLoading(true);
    const payload = {
      providerId: selectedDate?.provider.id,
      startDate: newStartTime,
      endDate: newEndTime,
      timezone: selectedDate?.clientTimezone,
      eventId: appointment?.id,
      clinicPreference: appointment?.clinicPreferenc,
      isPendingConfirmation: false,
      duration: duration,
      locationType: appointment?.location_type,
      locationCategory: appointment?.location_category,
      address: rescheduleAddress,
      telehealthLink: appointment?.link,
      paymentMethod: appointment?.paymentType,
      appointmentTypeId: appointment?.subtype_id,
      clientId: appointment?.client_id,
      clinicId: appointment?.clinic?.id,
      startingAddress: null,
      destinationAddress: null,
      miles: null,
      isLastDrive: false,
      reimburseableMiles: null,
      cancelReasonId: keyData,
      notes:
        LabelData === "Other"
          ? LabelData + ` from Patient Portal >> ` + textArea
          : LabelData + ` from Patient Portal`,
      clientEmailId: appointment?.client_email
    };

    console.log(payload, "[reschedule] payload confirmation");
    try {
      const response = await clientRescheduleAndCancelCall(
        ApiCalls.rsSubmittion,
        payload,
        getTokenSilently
      );
      if (response && response.data) {
        setConfirmLoading(false);
        if (response.data?.haveConflictingEvents === true) {
          history.push({
            pathname: "/client-reschedule-not-available",
            state: {
              appointment: appointment,
              activeDay: activeDay,
              path: path,
            },
          });
          setState((prevData: any) => ({
            ...prevData,
            selectedDate: null,
            initialDateSet: false,
            clickedIndex: -1,
            dropdown: default_Dropdown,
            textArea: "",
            rcSuggestions: undefined,
            error: false,
            newStartTime: undefined,
            newEndTime: undefined,
          }));
        } else {
          history.push({
            pathname: "/client-rescheduled",
            state: {
              appointment: appointment,
              path: path,
              newStartTime: newStartTime,
              newEndTime: newEndTime,
            },
          });
        }
        console.log(response, "reschedule confirm done");
      } else {
        setConfirmLoading(false);
        setState((prevData: any) => ({
          ...prevData,
          selectedDate: null,
          initialDateSet: false,
          clickedIndex: -1,
          dropdown: default_Dropdown,
          textArea: "",
          rcSuggestions: undefined,
          error: false,
          newStartTime: undefined,
          newEndTime: undefined,
        }));
        console.error("Error: No data received from API response");
      }
    } catch (err: any) {
      setConfirmLoading(false);
      console.log(
        err,
        err?.response,
        err?.response?.status,
        err?.response?.data?.error?.message ===
          "Request failed with status code 504"
          ? true
          : false,
        err?.response?.data?.error?.message,
        err?.response,
        err?.response?.status,
        "[reschedule] confirm patient error status"
      );
      if (
        (err.response && err?.response?.data?.error?.message === error_msg) ||
        (err.response && err?.response?.status === error_504_status)
      ) {
        // Ignore the 504 error
        history.push({
          pathname: "/client-rescheduled",
          state: {
            appointment: appointment,
            path: path,
            newStartTime: newStartTime,
            newEndTime: newEndTime,
          },
        });
      } else {
        history.push({
          pathname: "/client-reschedule-problem",
          state: {
            appointment: appointment,
            path: path,
          },
        });
        console.error("Error in API call:", err);
      }
    }
  }, [
    getTokenSilently,
    LabelData,
    addressData,
    appointment,
    dropdown,
    duration,
    history,
    newStartTime,
    newEndTime,
    path,
    selectedDate?.clientTimezone,
    selectedDate?.provider?.id,
    textArea,
  ]);

  console.log(LabelData, textArea, "[reschedule confirm] labelData");

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <>
      {confirmLoading ? (
        <div className="client-reschedule-confirm-loading">
          <Spinner />
          <span className="client-reschedule-confirm-loading-text">
            Confirming your appointment. Thanks for your patience!
          </span>
        </div>
      ) : (
        <>
          <div className="client-reschedule-confirm-container">
            <div className="client-reschedule-confirm-top">
              <span className="client-reschedule-confirm-heading">
                Time to review and confirm {appointment?.client}’s appointment.
              </span>

              <span className="client-reschedule-confirm-paragraph">
                Please confirm that you would like to reschedule the appointment
                below to the new appointment date and time. If you would like to
                change the appointment, please go back and select a new date
                and/or time.
              </span>
            </div>
            <div className="client-reschedule-confirm-middle">
              <div className="client-reschedule-confirm-box">
                <span className="client-reschedule-box-heading">
                  {appointment?.title} Appointment for {appointment?.client}
                </span>
                <span className="client-reschedule-box-sub-heading">
                  Reschedule Reason: {LabelData}
                </span>

                <div className="client-reschedule-inner-box">
                  <div className="client-reschedule-inner-box-left">
                    {prevAppointmentDetails.map((data: any, index: number) => (
                      <div
                        key={index}
                        className="client-reschedule-inner-left-div"
                      >
                        <img
                          className="client-reschedule-inner-left-img"
                          src={data.src}
                          alt={data.alt}
                        />
                        <del
                          className="client-reschedule-inner-left-del"
                          title={data.text}
                        >
                          {data.text}
                        </del>
                      </div>
                    ))}
                  </div>
                  <hr className="client-reschedule-ineer-box-divider"></hr>
                  <div className="client-reschedule-inner-box-right">
                    {nextAppointmentDetails.map((data: any, index: number) => (
                      <div
                        key={index}
                        className="client-reschedule-inner-left-div"
                      >
                        <img
                          className="client-reschedule-inner-left-img"
                          src={data.src}
                          alt={data.alt}
                        />
                        <span
                          className="client-reschedule-inner-left-span"
                          title={data.text}
                        >
                          {data.text}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="client-reschedule-confirm-end">
              <Button
                id="Reschedule-back-selection"
                size="large"
                type="default"
                className="client-reschedule-back-button"
                onClick={handleNavReschedule}
                style={{ pointerEvents: confirmLoading ? "none" : "auto" }}
              >
                Back
              </Button>
              <Button
                id="Reschedule-confirmation"
                size="large"
                type="default"
                className="client-reschedule-confirm-button"
                onClick={handleConfirmReschedule}
                loading={confirmLoading}
                style={{ pointerEvents: confirmLoading ? "none" : "auto" }}
              >
                {confirmLoading ? "loading..." : "Confirm"}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientRescheduleConfirm;
