import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  cancelAppointmentPayload,
  getObserverPayload,
  getTimeFromIso,
} from "./appointments.helper";
import { ConfirmAppointmentWrapper } from "./style";
import { CANCELLATION_REASONS } from "constant/BiPortalConstants";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faExclamationTriangle,
  faLocationArrow,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { APPT_TYPES } from "constant/AppConstants";
import { useHistory } from "react-router-dom";
import { Appointment, LeadEvent } from "model/appointments";
import { ApiCalls } from "./AllAppointmentsApi";
import Popup from "sharedComponents/reactHookComponents/popup/popup";
import { useSubmitApi } from "customHooks/authHook";
import { useMsal } from "@azure/msal-react";
import CorticaLoader from "sharedComponents/CorticaLoader/CorticaLoader";
import { LOADING_LABELS } from "constant/BiPortalConstants";

interface Props {
  providerId: number;
  leadEventId?: number;
  providerClinicId: number;
  providerName: string;
  appointment: Appointment;
  leadEvent?: LeadEvent;
  providerClinicTimeZone: string;
}

const ConfirmCancellation: React.FC<Props> = ({
  providerId,
  leadEventId,
  providerClinicId,
  providerName,
  appointment,
  leadEvent,
  providerClinicTimeZone
}) => {
  const methods = useFormContext();
  const { instance } = useMsal();
  const { submitApiCall } = useSubmitApi();
  const history = useHistory();
  const [softloading, setsoftloading] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const activeAccount = instance.getActiveAccount();

  const cancelReason: string = methods.getValues("cancellationId");
  const note: string = methods.getValues("note");

  const displayCancelReason = CANCELLATION_REASONS.filter((reason) => {
    return reason.value === cancelReason;
  })[0];

  const isObserver = appointment.leadEventId ? true : false;

  const displayDate = moment(new Date(appointment.start)).format(
    " MMMM DD, YYYY"
  );

  const displayTime = getTimeFromIso(appointment.start, appointment.end);

  const homeRedirection = () => {
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: appointment.start,
        hardCancellation: appointment.id,
      },
    });
  };

  const appointmentStartDate = leadEvent
    ? new Date(leadEvent.startDate!).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : null;
  const appointmentStartTime = leadEvent
    ? new Date(leadEvent.startDate!).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;

  const appointmentEndTime = leadEvent
    ? new Date(leadEvent.endDate!).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;

  async function cancelAppointment() {
    if (activeAccount?.username) {
      if (isObserver) {
        setsoftloading(true);
        setBackendError(false);
        const payloadDATA = getObserverPayload(
          {
            startDate: leadEvent!.startDate!,
            endDate: leadEvent!.startDate!,
          },
          leadEvent!,
          appointment.id,
          appointment.provider_name,
          "cancel"
        );
        try {
          await submitApiCall(ApiCalls.UpdateObservations, payloadDATA);
          setsoftloading(false);
          homeRedirection();
        } catch (error) {
          setBackendError(true);
          setsoftloading(false);
        }
      } else {
        const payload = {
          providerId: providerId,
          providerClinicId: providerClinicId,
          providerName: providerName,
          providerEmail: activeAccount.username,
          leadEventId: leadEventId,
          appointmentType: appointment.appointment_type,
          startDate: appointment.start,
          endDate: appointment.end,
          clinicId: appointment.clinic.id,
          providerClinicTimeZone: providerClinicTimeZone
        };

        const payloadData = cancelAppointmentPayload(
          payload,
          cancelReason,
          displayCancelReason.label,
          appointment,
          note
        );
        setsoftloading(true);
        setBackendError(false);
        try {
          await submitApiCall(ApiCalls.ConfirmCancelCall, payloadData);
          setsoftloading(false);
          homeRedirection();
        } catch (error) {
          setBackendError(true);
          setsoftloading(false);
        }
      }
    } else {
      setBackendError(true);
    }
  }

  const submitData = () => {
    cancelAppointment();
  };

  const handleClosePopUp = () => {
    setBackendError(false);
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: appointment.start,
        hardCancellation: appointment.id,
      },
    });
  };

  return (
    <ConfirmAppointmentWrapper>
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleClosePopUp}
            heading={"Sorry, Failed to Cancel the Appointment"}
            paragraph={
              "The cancellation has failed due to server error. But sometimes the cancellation can still happen. You can check the appointments list on the home screen and perform this operation again after a few minutes."
            }
          />
        </>
      )}
      <div className="wrapper-container">
        {softloading && (
          <CorticaLoader message={LOADING_LABELS.cancelAppt}></CorticaLoader>
        )}
        {!softloading && (
          <div className="info-content-div w-100">
            {leadEvent && appointmentStartTime && appointmentEndTime && (
              <div className="edit-appt-details">
                <span className="lead-event-text">
                  This appointment will be removed from the observation
                  appointment list of{" "}
                  <strong>{leadEvent.provider.displayName}</strong>'s lead
                  appointment starting from{" "}
                  <strong>{appointmentStartTime.toLowerCase()}</strong> -{" "}
                  <strong>{appointmentEndTime.toLowerCase()}</strong> on{" "}
                  {appointmentStartDate}.
                </span>
              </div>
            )}
            <div className="span-block">
              {appointment.appointment_type === APPT_TYPES.admin ? (
                <span>{appointment.title}</span>
              ) : (
                <>
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faUser} />
                  </span>
                  <span>
                    {appointment?.client_name}, {appointment.title}
                  </span>
                </>
              )}
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faCalendar} />
              </span>
              <span>{displayDate}</span>
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faClock} />
              </span>
              <span>
                {displayTime.startTime.toLowerCase()} -{" "}
                {displayTime.endTime.toLowerCase()}
              </span>
            </div>
            {appointment.appointment_type !== "Admin" && (
              <>
                <div className="span-block">
                  <span className="icon-span">
                    <FontAwesomeIcon
                      className="icon-back"
                      icon={faLocationArrow}
                    />
                  </span>
                  {appointment.location_type !== "Telehealth" ? (
                    <span>
                      {appointment.address?.addressLine1}.{" "}
                      {appointment.address?.city}, {appointment.address?.state},{" "}
                      {appointment.address?.zipCode}
                    </span>
                  ) : appointment.link !== "N/A" ? (
                    <span>{appointment.link}</span>
                  ) : (
                    <span>{appointment.location_type}</span>
                  )}
                </div>
              </>
            )}
            {!appointment.leadEventId && (
              <div className="span-block">
                <span>
                  <span className="bolder-custom-span">
                    Cancellation Reason:
                  </span>{" "}
                  {displayCancelReason?.label}
                </span>
              </div>
            )}
            {note && (
              <div className="span-block">
                <span>
                  <span className="bolder-custom-span">Note:</span> {note}
                </span>
              </div>
            )}
            {appointment.observations &&
              appointment.observations.length > 0 && (
                <>
                  <div className="span-block txt-center">
                    <span className="icon-span">
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faExclamationTriangle}
                      />
                    </span>
                    <span className="bolder-custom-span margin-r-2">
                      Observations Warning:
                    </span>
                  </div>

                  <div className="span-block">
                    <span className="txt-center">
                      The observer events associated with this event will be
                      canceled.
                    </span>
                  </div>
                </>
              )}

            <div className="submit-button">
              <button onClick={submitData}>Confirm</button>
            </div>
          </div>
        )}
      </div>
    </ConfirmAppointmentWrapper>
  );
};

export default React.memo(ConfirmCancellation);
